<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">User View</h4>
                <div>
                  <b-button variant="outline-red" squared @click="openDeleteModal" size="sm">Delete</b-button>
                  <b-button variant="outline-primary" squared @click="openPasswordModal" size="sm" class="ml-2">Reset
                    Password
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="userData.name"
                              @blur="$v.selectedUser.name.$touch()"></b-form-input>
                <div v-if="$v.selectedUser.name.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedUser.name.required">This is a required field</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Surname <span class="text-red">*</span></label>
                <b-form-input v-model="userData.surname"
                              @blur="$v.selectedUser.surname.$touch()"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Role</label>
                <b-form-select v-model="userData.roleID">
                  <b-form-select-option v-for="(item, index) in roles" :key="index" :value="item.id"
                                        @blur="item.roleID.$touch()">{{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Contact Number</label>
                <b-form-input v-model="userData.phoneNumber"
                              @blur="$v.selectedUser.phoneNumber"></b-form-input>
                <div v-if="$v.selectedUser.phoneNumber.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedUser.phoneNumber.minLength || !$v.selectedUser.phoneNumber.maxLength">Please
                    enter a valid number</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Email <span class="text-red">*</span></label>
                <b-form-input v-model="userData.email"
                              @blur="$v.selectedUser.email.$touch"></b-form-input>
                <div v-if="$v.selectedUser.email.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedUser.email.required">This is a required field</p>
                  <p v-if="!$v.selectedUser.email.email">Please enter a valid email address</p>
                </div>
              </b-col>
              <b-col cols="4">
                <b-form-group id="vendor-input-group" label="Vendor" label-for="vendor">
                  <b-form-select id="vendor"
                                 name="vendor"
                                 v-model="userData.vendorName"
                                 @blur="$v.selectedUser.vendorName.$model"
                                 aria-describedby="company-live-feedback">
                    <b-form-select-option v-for="(item, index) in companies" :key="index" :value="item.name">
                      {{ item.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>


              </b-col>


            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group id="email-input-group" label="Land Line" label-for="landline">
                  <b-form-input
                      id="landline"
                      name="landline"
                      v-model="userData.landline"
                      @blur="$v.selectedUser.landline.$model"

                      aria-describedby="landline-live-feedback"
                  ></b-form-input>

                </b-form-group>

              </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="updateUser()" class="ml-2">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="userModal" hide-footer hide-header-close title="Delete User">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span
              class="font-weight-bold text-red text-center">{{ this.selectedUser.name }} {{ this.selectedUser.surname }}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hideDeleteModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="red" @click="removeUser" squared>Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
    <b-modal id="passwordModal" hide-footer hide-header-close title="Reset User Password">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are are about to reset <span
              class="font-weight-bold text-red text-center">{{ this.selectedUser.name }} {{ this.selectedUser.surname }}</span>
            password</label>
          <label class="text-center">Please type a new password</label>
        </b-col>
      </b-row>
      <b-row>
        <b-form-input v-model="userData.password"></b-form-input>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hidePasswordModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="primary" @click="updatePassword" squared>Reset Password</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}
</style>
<script>
import {mapMutations, mapState} from "vuex";
import {required, email, minLength, maxLength, numeric} from "vuelidate/lib/validators";
import api from "../../../api";

export default {
  name: "userView",

  data: () => ({
    state: 'show',
    userData: {
      id: 0,
      name: null,
      surname: null,
      email: null,
      phoneNumber: null,
      company: null,
      vendorName: null,
      landline: null,
      roleID: null,
      role: [],
      password: null,
    },
    companies:[],
    roles: [],
  }),
  created() {
    this.setBreadcrumb([
      {
        text: 'User'
      },
      {
        text: 'Details'
      },
    ]);

    //Load Vendors for List
    api.GetVendors(s => {
      this.companies = s
      this.companies.unshift({ code: "", id: "", key: 0, name: "None" });
    }, console.error)

    const e = (msg) => {
      console.log(msg);
    };

    const rs = (r) => {
      this.roles = r;
      console.log(this.roles);

      const s = (d) => {
        this.userData = d;
        this.userData.roleID = this.userData.role.id;
        console.log(d);
        this.state = 'show';


      };


      this.userData.email = localStorage.getItem("view_user_email");
      api.getUserID(this.userData.email, s, e);
    };
    api.getRoles(rs, e);


  },


  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    loadRoles() {

    },

    updateUser() {
      const s = (data) => {
        this.$router.push({path: '/admin/ManageUser/SearchUser'})
        console.log(data);
      };

      const e = (msg) => {
        console.log(msg);
        var newMsg = msg.substring(msg.indexOf(':') + 2, 76);
        this.makeToast(newMsg);
      };

      console.log(this.userData);

      this.userData.role = {
        "id": this.userData.roleID
      };
      api.UpdateUser(this.userData, s, e)
    },

    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },
    updatePassword() {
      const s = (data) => {
        console.log(data);
        this.hidePasswordModal()
      };

      const e = (msg) => {
        console.log(msg);
        this.hidePasswordModal()
      };

      console.log(this.userData);

      this.userData.role = {
        "id": this.userData.roleID
      };
      api.UpdateUser(this.userData, s, e)
    },


    goBackToSearch() {
      this.$router.push({path: '/admin/ManageUser/SearchUser'})
    },

    removeUser() {

      const s = () => {
        this.$router.push({path: '/admin/ManageUser/SearchUser'})

      };

      const e = (msg) => {
        console.log("Show Error")

        console.log("Error message", msg);
        this.makeToast(msg)

      };
      this.userData.favorites = []
      api.DeleteUser(this.userData, s, e);
      this.hideDeleteModal();


    },

    openDeleteModal() {
      this.$bvModal.show('userModal')
    },

    openPasswordModal() {
      this.$bvModal.show('passwordModal')
    },

    hideDeleteModal() {
      this.$bvModal.hide('userModal')
    },

    hidePasswordModal() {
      this.$bvModal.hide('passwordModal')
    },


  },
  computed: {
    ...mapState([
      'selectedUser'
    ])
  },
  validations: {
    selectedUser: {
      name: {required},
      surname: {required},
      email: {email, required},
      phoneNumber: {minLength: minLength(10), maxLength: maxLength(10)},
      landline: {minLength: minLength(10), maxLength: maxLength(10), numeric},
      vendorName: {required},
    },
  },
}
</script>

<style scoped>

</style>